<template>
  <MessageTable :isHistory="true"></MessageTable>
</template>
<script>
//  import { Dropdown, DropdownItem, DropdownMenu, Table, TableColumn } from 'element-ui';
import MessageTable from "./MessageTable.vue";

export default {
  name:'message-history',
  inject: ["userStore"],
  props:{
  isHistory: {
      type: Boolean,
      default: false, 
      description: 'If true then get done messages instead'
    }
  },
  components: {
    MessageTable
  }
 
}

</script>


  <style scoped>
  .box {
    inline-size: 400px;
    overflow-wrap: break-word;
}
  
  </style>


     